import axios from "axios";
export default {
  POST_API_JSON({ commit }, { ...args }) {
    // Раскомментировать/закомментировать следующие 2 строки для некорректной/корректной работы.
    // alert("Временно не работает.");
    // return;
    let dataToSend = { ...args.data };
    if (this.getters.TOKEN.length) dataToSend.token = this.getters.TOKEN;
    return axios(`${this.getters.API}${args.src}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: dataToSend,
    })
      .then((response) => {
        if (response) {
          if (response.data.status == 0) {
            if (args.mutation) {
              commit(args.mutation, response.data.data);
            }
          } else if (response.data.status == 8 || response.data.status == 9) {
            return axios(`${this.getters.API}/logout`, {
              method: "POST",
              headers: {
                "Content-Type": "multipart/form-data",
              },
              data: this.getters.TOKEN,
            }).then(() => {
              this.state.token = "";
              commit("LOG_OUT", this.state);
              alert("Срок годности токена истек! Выполните вход заново.");
            });
          }
        }
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  POST_API({ commit }, { ...args }) {
    // Раскомментировать/закомментировать следующие 2 строки для некорректной/корректной работы.
    // alert("Временно не работает.");
    // return;
    let dataToSend;
    let destination;
    if (args?.isGifts) {
      destination = "https://tcecei.space/get_gifts?team=" + this.getters.USER.team;
    } else {
      dataToSend = { ...args.data };
      if (this.getters.TOKEN.length) dataToSend.token = this.getters.TOKEN;
      destination = `${this.getters.API}${args.src}`;
    }

    return axios(destination, {
      method: args?.isGifts ? "GET" : "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      data: dataToSend,
    })
      .then((response) => {
        if (response) {
          if (args?.isGifts && response.data.length) {
            commit(args.mutation, response.data);
          }
          if (response.data.status == 0) {
            if (args.mutation) {
              commit(args.mutation, response.data.data);
            }
          } else if (response.data.status == 8 || response.data.status == 9) {
            return axios(`${this.getters.API}/logout`, {
              method: "POST",
              headers: {
                "Content-Type": "multipart/form-data",
              },
              data: this.getters.TOKEN,
            }).then(() => {
              this.state.token = "";
              commit("LOG_OUT", this.state);
              alert("Срок годности токена истек! Выполните вход заново.");
            });
          }
        }
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
};
