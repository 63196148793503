import { createStore } from "vuex";
// Actions
import commonActions from "@/store/actions/actions";
import apiRequestsGet from "@/store/actions/api-requests";
import productsGet from "@/store/actions/products";
const actions = {
  ...commonActions,
  ...apiRequestsGet,
  ...productsGet,
};

// Mutations
import commonMutations from "@/store/mutations/mutations";
import apiRequestsSet from "@/store/mutations/api-request";
import productsSet from "@/store/mutations/products";
const mutations = {
  ...commonMutations,
  ...apiRequestsSet,
  ...productsSet,
};

// Getters
import getters from "@/store/getters/getters";

export default createStore({
  state: {
    accordeon: null,

    api: `https://infinity-hub.info`,
    // api: `http://192.168.8.219:5000`,
    token: "",

    isAuthenticated: false,
    isSafariMobile: false,
    isLoader: false,
    is_sidebar_opened: true,
    theme: "dark",

    user: {},

    countries: [],
    brocard_countries: [],

    // ! TemaplatePageList
    blacks_total: 0,
    blacks: [],
    blacks_countries: [],
    blacks_categories: [],
    blacks_offers: [],

    whites_total: 0,
    whites: [],
    whites_countries: [],
    whites_categories: [],
    whites_offers: [],

    free_whites_total: 0,
    free_whites: [],
    free_whites_countries: [],
    free_whites_categories: [],
    free_whites_offers: [],

    thanks_total: 0,
    thanks: [],
    thanks_countries: [],
    thanks_categories: [],
    thanks_offers: [],

    get_gifts: [],
    // ! TemaplatePageList

    // ! Users
    users_teams: [],
    users: [],
    users_total: 0,
    // ! Users

    links: [
      {
        route: "earnings",
        link: "Earnings",
      },
      {
        route: "template-page-list",
        link: "Blacks list",
        url: "blacks-list",
        pages: "BLACKS",
      },
      {
        route: "template-page-list",
        link: "Whites list",
        url: "whites-list",
        pages: "WHITES",
      },
      {
        route: "template-page-list",
        link: "Free Whites list",
        url: "free-whites-list",
        pages: "FREE_WHITES",
      },
      {
        route: "template-page-list",
        link: "Thanks list",
        url: "thanks-list",
        pages: "THANKS",
      },
      {
        route: "template-page-list",
        link: "Gifts list",
        url: "gifts-list",
        pages: "GET_GIFTS",
      },
      {
        route: "dashboard",
        link: "Dashboard",
      },
      // {
      //   route: "blacks",
      //   link: "Blacks",
      // },
      // {
      //   route: "whites",
      //   link: "Whites",
      // },
      // {
      //   route: "domens",
      //   link: "Domens",
      // },
      // {
      //   route: "statistics",
      //   link: "Statistics",
      // },
      {
        route: "users",
        link: "Users list",
      },
      // {
      //   route: "profile",
      //   link: "Profile",
      // },
    ],
  },
  mutations,
  actions,
  getters,
  modules: {},
});
