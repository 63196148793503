export default {
  IS_SIDEBAR_OPENED(state) {
    return state.is_sidebar_opened;
  },
  THEME(state) {
    return state.theme;
  },
  LOADER(state) {
    return state.isLoader;
  },
  IS_SAFARI_MOBILE(state) {
    return state.isSafariMobile;
  },
  IS_AUTH(state) {
    return state.isAuthenticated;
  },
  LOCALE(state) {
    return state.locale;
  },
  USER(state) {
    return state.user;
  },

  // API
  API(state) {
    return state.api;
  },
  TOKEN(state) {
    return state.token;
  },

  COUNTRIES(state) {
    return state.countries;
  },
  BROCARD_COUNTRIES(state) {
    return state.brocard_countries;
  },
  // Blacks
  BLACKS_TOTAL(state) {
    return state.blacks_total;
  },
  BLACKS(state) {
    return state.blacks;
  },
  BLACKS_CATEGORIES(state) {
    return state.blacks_categories;
  },
  BLACKS_COUNTRIES(state) {
    return state.blacks_countries;
  },
  BLACKS_OFFERS(state) {
    return state.blacks_offers;
  },

  WHITES_TOTAL(state) {
    return state.whites_total;
  },
  WHITES(state) {
    return state.whites;
  },
  WHITES_CATEGORIES(state) {
    return state.whites_categories;
  },
  WHITES_COUNTRIES(state) {
    return state.whites_countries;
  },
  WHITES_OFFERS(state) {
    return state.whites_offers;
  },

  FREE_WHITES_TOTAL(state) {
    return state.free_whites_total;
  },
  FREE_WHITES(state) {
    return state.free_whites;
  },
  FREE_WHITES_CATEGORIES(state) {
    return state.free_whites_categories;
  },
  FREE_WHITES_COUNTRIES(state) {
    return state.free_whites_countries;
  },
  FREE_WHITES_OFFERS(state) {
    return state.free_whites_offers;
  },

  THANKS_TOTAL(state) {
    return state.thanks_total;
  },
  THANKS(state) {
    return state.thanks;
  },
  THANKS_CATEGORIES(state) {
    return state.thanks_categories;
  },
  THANKS_COUNTRIES(state) {
    return state.thanks_countries;
  },
  THANKS_OFFERS(state) {
    return state.thanks_offers;
  },

  GET_GIFTS(state) {
    return state.get_gifts;
  },

  // Служебная информация
  ACCORDEON(state) {
    return state.accordeon;
  },

  // ! Users
  USERS_TEAMS(state) {
    return state.users_teams;
  },
  USERS(state) {
    return state.users;
  },
  USERS_TOTAL(state) {
    return state.users_total;
  },

  // ! Users
};
