<template>
  <BaseLoader />
  <div class="theme">
    <div
      v-if="THEME === 'light'"
      class="theme__dark"
      @click="setDarkTheme"
    ></div>
    <div
      v-if="THEME === 'dark'"
      class="theme__light"
      @click="setLightTheme"
    ></div>
  </div>
  <div class="wrapper">
    <BaseHeader v-if="routeName !== 'login' && IS_AUTH" />
    <main :class="{ loggedIn: routeName !== 'login' }">
      <router-view />
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseLoader from "@/components/UI/BaseLoader.vue";
import BaseHeader from "@/components/header/BaseHeader.vue";
// import BaseFooter from "@/components/footer/BaseFooter.vue";
export default {
  name: "",
  components: {
    BaseLoader,
    BaseHeader,
    // BaseFooter
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "IS_AUTH",
      "THEME",
      "TOKEN",
      "USER",
      // NAME
    ]),
    routeName() {
      return this.$router.currentRoute.value.name;
    },
  },
  methods: {
    ...mapActions([
      "SET_SAFARI_MOBILE",
      "CHANGE_THEME",
      "LOGIN",
      "LOGOUT",

      // NAME
    ]),
    detectBrowser() {
      let userAgent = navigator.userAgent.toLowerCase();
      // if (userAgent.indexOf("safari") !== -1 && userAgent.indexOf("mobile") !== -1) {
      // Проверяем, является ли браузер Safari и выполняется ли код на мобильном устройстве или планшете
      if (userAgent.indexOf("safari") === -1 && userAgent.indexOf("mobile") === -1) {
        this.SET_SAFARI_MOBILE();
      }
    },
    setDarkTheme() {
      this.CHANGE_THEME("dark");
    },
    setLightTheme() {
      this.CHANGE_THEME("light");
    },
  },
  watch: {
    TOKEN() {
      if (!this.TOKEN) {
        this.$router.push({ name: "login" });
      }
    },
    $route() {
      if (this.$route.name == "login") document.body.style.paddingLeft = "0px";
      // if (this.USER.id_nn != 3) {
      //   this.LOGOUT();
      //   this.$router.push({ name: "login" });
      //   alert("Извините. Сайт на данный момент не работает! В скором времени все будет ок...");
      // }
    },
  },
  created() {
    this.detectBrowser();
    let isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
    let token = JSON.parse(localStorage.getItem("token"));
    if (token && isAuthenticated) {
      this.LOGIN({ token: token, condition: isAuthenticated });
      let user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        this.$store.commit("SET_USER", user);
      }
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
    }
  },
  beforeMount() {},
  mounted() {
    setTimeout(() => {
      console.log(this.USER);
    }, 1000);
    let localTheme = localStorage.getItem("theme");
    if (localTheme === "dark" || localTheme === "light") {
      this.CHANGE_THEME(localTheme);
    } else {
      this.CHANGE_THEME(this.THEME);
    }
    // Закомментировать следующую строку для коррекетной работы!
    // this.LOGOUT();
  },
  beforeUnmount() {},
  unmounted() {},
  beforeUpdate() {},
  updated() {},
};
</script>
<style lang="scss">
body {
  &.lock {
    overflow: hidden;
  }
  &.dark {
    background-color: $mainBackgroundDV;
    color: $textNormalDV;
  }
  &.light {
    background-color: $mainBackgroundLV;
    color: $textNormalLV;
  }
  &.light,
  &.dark {
    @media (min-width: 990px) {
      padding-left: 200px;
    }
    @media (max-width: 989px) {
      padding-left: 0 !important;
    }
  }
}
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: $ff1;
  @include rem(16);
  line-height: 1;
  main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    &.loggedIn {
      // > :first-child {
      //    display: grid;
      //    align-items: center;
      // }
      position: relative;
      @media (max-width: 989px) {
        padding: 10px;
      }
      @media (min-width: 990px) {
        padding: 25px;
        // box-sizing: border-box;
        // height: 100vh !important;
        // overflow: hidden !important;
        // > :first-child {
        //    overflow-y: auto;
        // }
      }
    }
    > :first-child {
      flex: 1 1 auto;
    }
    &:only-child {
      width: 100vw;
    }
  }
  img {
    max-width: 100%;
  }
}
.copy-notification {
  position: fixed;
  z-index: 100;
  padding: 5px 10px;
  border: 1px solid;
  display: inline-block;
  @extend %rad1;
  font-family: $ff1;
  font-weight: 500;

  &.dark {
    background-color: $backgroundgDV;
    border-color: $blueDV;
    // color: ;
  }
  &.light {
    background-color: $backgroundgLV;
    border-color: $blueLV;
    // color: ;
  }
}
.theme {
  position: fixed;
  z-index: 5;
  @media (max-width: 989px) {
    right: 10px;
    bottom: 10px;
  }
  @media (min-width: 990px) {
    right: 25px;
    bottom: 25px;
  }
  padding: 10px;
  display: grid;
  grid-template-columns: 20px;
  column-gap: 10px;
  background-color: #cacaca;
  border-radius: 12px;
  // .theme__dark

  &__dark {
    cursor: pointer;
    height: 20px;
    border-radius: 50%;
    background-color: $backgroundgDV;
  }

  // .theme__light

  &__light {
    cursor: pointer;
    height: 20px;
    border-radius: 50%;
    background-color: $backgroundgLV;
  }
}
.wrapper {
  min-height: 100vh;
  @media (max-width: 989px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 990px) {
    display: grid;
    grid-template-columns: 1fr;
  }
}
</style>
