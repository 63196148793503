import { createRouter, createWebHistory } from "vue-router";
import { routes } from "@/router/routes";
import store from "@/store/store";
const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes,
});

router.beforeEach((to) => {
   // Закомментировать следующую строку для коррекетной работы!
   // store.dispatch("LOGOUT");

   document.body.classList.remove("lock");
   if (to.meta.isNeedAuth && !store.state.isAuthenticated) {
      return { name: "login" };
   }
});
router.afterEach(() => {
   window.scrollTo({ top: 0, behavior: "instant" });
});

export default router;
