export default {
   SET_SAFARI_MOBILE({ commit }) {
      commit("SET_SAFARI_MOBILE_TO_TRUE");
   },
   SHOW_LOADER({ commit }) {
      commit("SET_LOADER_TO_TRUE");
   },
   HIDE_LOADER({ commit }) {
      commit("SET_LOADER_TO_FALSE");
   },
   LOGIN({ commit }, data) {
      commit("LOG_IN", data);
   },
   LOGOUT({ commit }) {
      commit("LOG_OUT");
   },
   CHANGE_THEME({ commit }, theme) {
      commit("SET_THEME", theme);
   },
};
