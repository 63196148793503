export default {
   SET_SAFARI_MOBILE_TO_TRUE: (state) => {
      state.isSafariMobile = true;
   },
   SET_LOADER_TO_TRUE: (state) => {
      state.isLoader = true;
   },
   SET_LOADER_TO_FALSE: (state) => {
      state.isLoader = false;
   },
   LOG_IN: (state, data) => {
      state.isAuthenticated = true;
      state.token = data.token;
      localStorage.setItem("token", JSON.stringify(data.token));
      if (data.condition) localStorage.setItem("isAuthenticated", JSON.stringify(true));
   },
   LOG_OUT: (state) => {
      state.isAuthenticated = false;
      state.token = "";
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
   },
   SET_THEME: (state, theme) => {
      state.theme = theme;
      document.body.classList.remove("light");
      document.body.classList.remove("dark");
      document.body.classList.add(theme);
      localStorage.setItem("theme", theme);
   },

   // HUB
   SET_ACCORDEON: (state, data) => {
      state.accordeon = data;
   },
};
