<template>
  <section
    class="navigation"
    ref="navigation"
    :class="{ opened: IS_SIDEBAR_OPENED, active: isMenuActive, dark: THEME === 'dark', light: THEME === 'light' }"
  >
    <div class="navigation__header navigation-header">
      <!-- <router-link :to="{ name: 'dashboard' }"> -->
      <router-link :to="{ name: 'users' }">
        <div class="navigation-header__logo">
          <img
            :class="{ dark: THEME === 'dark', light: THEME === 'light' }"
            src="../../assets/img/logo.png"
            alt="Logo"
          />
        </div>
      </router-link>
    </div>
    <div
      class="navigation__content"
      ref="menu"
    >
      <div
        class="navigation__content-container"
        :class="{ dark: THEME === 'dark', light: THEME === 'light' }"
      >
        <nav
          class="navigation__menu navigation-menu"
          :class="{ opened: IS_SIDEBAR_OPENED, dark: THEME === 'dark', light: THEME === 'light' }"
        >
          <ul class="navigation-menu__list">
            <template
              v-for="item in this.$store.state.links"
              :key="item.route"
            >
              <router-link
                :title="item.route"
                v-if="item.route == 'earnings' && USER.type != 'tech' && USER.type != 'shrek'"
                :to="{
                  name: item.route,
                  params: { url: item.url },
                  query: { ...item },
                }"
              >
                <li
                  class="navigation-menu__list-item"
                  :class="{ dark: THEME === 'dark', light: THEME === 'light', small: !IS_SIDEBAR_OPENED }"
                >
                  <div
                    class="navigation-menu__icon"
                    :class="{ dark: THEME === 'dark', light: THEME === 'light' }"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.33 0H14.669C18.07 0 19.99 1.929 20 5.33V14.67C20 18.07 18.07 20 14.669 20H5.33C1.929 20 0 18.07 0 14.67V5.33C0 1.929 1.929 0 5.33 0ZM10.049 15.86C10.48 15.86 10.839 15.54 10.879 15.11V4.92C10.919 4.61 10.77 4.299 10.5 4.13C10.219 3.96 9.879 3.96 9.61 4.13C9.339 4.299 9.19 4.61 9.219 4.92V15.11C9.27 15.54 9.629 15.86 10.049 15.86ZM14.65 15.86C15.07 15.86 15.429 15.54 15.48 15.11V11.83C15.509 11.509 15.36 11.21 15.089 11.04C14.82 10.87 14.48 10.87 14.2 11.04C13.929 11.21 13.78 11.509 13.82 11.83V15.11C13.86 15.54 14.219 15.86 14.65 15.86ZM6.219 15.11C6.179 15.54 5.82 15.86 5.389 15.86C4.959 15.86 4.599 15.54 4.56 15.11V8.2C4.53 7.889 4.679 7.58 4.95 7.41C5.219 7.24 5.56 7.24 5.83 7.41C6.099 7.58 6.25 7.889 6.219 8.2V15.11Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div class="navigation-menu__link-name">{{ item.link }}</div>
                </li>
              </router-link>
              <template v-if="USER.type == 'shrek'">
                <router-link
                  v-if="item.route == 'template-page-list'"
                  :to="{
                    name: item.route,
                    params: { url: item.url },
                    query: { ...item },
                  }"
                >
                  <li
                    class="navigation-menu__list-item"
                    :class="{ dark: THEME === 'dark', light: THEME === 'light', small: !IS_SIDEBAR_OPENED }"
                  >
                    <div
                      class="navigation-menu__icon"
                      :class="{ dark: THEME === 'dark', light: THEME === 'light' }"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.33 0H14.669C18.07 0 19.99 1.929 20 5.33V14.67C20 18.07 18.07 20 14.669 20H5.33C1.929 20 0 18.07 0 14.67V5.33C0 1.929 1.929 0 5.33 0ZM10.049 15.86C10.48 15.86 10.839 15.54 10.879 15.11V4.92C10.919 4.61 10.77 4.299 10.5 4.13C10.219 3.96 9.879 3.96 9.61 4.13C9.339 4.299 9.19 4.61 9.219 4.92V15.11C9.27 15.54 9.629 15.86 10.049 15.86ZM14.65 15.86C15.07 15.86 15.429 15.54 15.48 15.11V11.83C15.509 11.509 15.36 11.21 15.089 11.04C14.82 10.87 14.48 10.87 14.2 11.04C13.929 11.21 13.78 11.509 13.82 11.83V15.11C13.86 15.54 14.219 15.86 14.65 15.86ZM6.219 15.11C6.179 15.54 5.82 15.86 5.389 15.86C4.959 15.86 4.599 15.54 4.56 15.11V8.2C4.53 7.889 4.679 7.58 4.95 7.41C5.219 7.24 5.56 7.24 5.83 7.41C6.099 7.58 6.25 7.889 6.219 8.2V15.11Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div class="navigation-menu__link-name">{{ item.link }}</div>
                  </li>
                </router-link>
              </template>
              <template v-else>
                <router-link
                  v-if="item.route != 'earnings'"
                  :to="{
                    name: item.route,
                    params: { url: item.url },
                    query: { ...item },
                  }"
                >
                  <li
                    class="navigation-menu__list-item"
                    :class="{ dark: THEME === 'dark', light: THEME === 'light', small: !IS_SIDEBAR_OPENED }"
                  >
                    <div
                      class="navigation-menu__icon"
                      :class="{ dark: THEME === 'dark', light: THEME === 'light' }"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.33 0H14.669C18.07 0 19.99 1.929 20 5.33V14.67C20 18.07 18.07 20 14.669 20H5.33C1.929 20 0 18.07 0 14.67V5.33C0 1.929 1.929 0 5.33 0ZM10.049 15.86C10.48 15.86 10.839 15.54 10.879 15.11V4.92C10.919 4.61 10.77 4.299 10.5 4.13C10.219 3.96 9.879 3.96 9.61 4.13C9.339 4.299 9.19 4.61 9.219 4.92V15.11C9.27 15.54 9.629 15.86 10.049 15.86ZM14.65 15.86C15.07 15.86 15.429 15.54 15.48 15.11V11.83C15.509 11.509 15.36 11.21 15.089 11.04C14.82 10.87 14.48 10.87 14.2 11.04C13.929 11.21 13.78 11.509 13.82 11.83V15.11C13.86 15.54 14.219 15.86 14.65 15.86ZM6.219 15.11C6.179 15.54 5.82 15.86 5.389 15.86C4.959 15.86 4.599 15.54 4.56 15.11V8.2C4.53 7.889 4.679 7.58 4.95 7.41C5.219 7.24 5.56 7.24 5.83 7.41C6.099 7.58 6.25 7.889 6.219 8.2V15.11Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div class="navigation-menu__link-name">{{ item.link }}</div>
                  </li>
                </router-link>
              </template>
              <!-- <router-link :to="{ name: item.route }">
                        <li
                           class="navigation-menu__list-item"
                           :class="{ dark: THEME === 'dark', light: THEME === 'light', small: !IS_SIDEBAR_OPENED }"
                        >
                           <div
                              class="navigation-menu__icon"
                              :class="{ dark: THEME === 'dark', light: THEME === 'light' }"
                           >
                              <svg
                                 width="20"
                                 height="20"
                                 viewBox="0 0 20 20"
                                 fill="none"
                              >
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M5.33 0H14.669C18.07 0 19.99 1.929 20 5.33V14.67C20 18.07 18.07 20 14.669 20H5.33C1.929 20 0 18.07 0 14.67V5.33C0 1.929 1.929 0 5.33 0ZM10.049 15.86C10.48 15.86 10.839 15.54 10.879 15.11V4.92C10.919 4.61 10.77 4.299 10.5 4.13C10.219 3.96 9.879 3.96 9.61 4.13C9.339 4.299 9.19 4.61 9.219 4.92V15.11C9.27 15.54 9.629 15.86 10.049 15.86ZM14.65 15.86C15.07 15.86 15.429 15.54 15.48 15.11V11.83C15.509 11.509 15.36 11.21 15.089 11.04C14.82 10.87 14.48 10.87 14.2 11.04C13.929 11.21 13.78 11.509 13.82 11.83V15.11C13.86 15.54 14.219 15.86 14.65 15.86ZM6.219 15.11C6.179 15.54 5.82 15.86 5.389 15.86C4.959 15.86 4.599 15.54 4.56 15.11V8.2C4.53 7.889 4.679 7.58 4.95 7.41C5.219 7.24 5.56 7.24 5.83 7.41C6.099 7.58 6.25 7.889 6.219 8.2V15.11Z"
                                    fill="white"
                                 />
                              </svg>
                           </div>
                           <div class="navigation-menu__link-name">{{ item.link }}</div>
                        </li>
                     </router-link> -->
            </template>
          </ul>
        </nav>
        <div class="navigation-menu__actions">
          <div class="navigation-menu__icon-button">
            <span @click="changeState">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 28C17.5 30.0767 18.1158 32.1068 19.2696 33.8335C20.4233 35.5602 22.0632 36.906 23.9818 37.7007C25.9004 38.4955 28.0116 38.7034 30.0484 38.2982C32.0852 37.8931 33.9562 36.8931 35.4246 35.4246C36.8931 33.9562 37.8931 32.0852 38.2982 30.0484C38.7034 28.0116 38.4955 25.9004 37.7007 23.9818C36.906 22.0632 35.5602 20.4233 33.8335 19.2696C32.1068 18.1158 30.0767 17.5 28 17.5C25.2152 17.5 22.5445 18.6062 20.5754 20.5754C18.6062 22.5445 17.5 25.2152 17.5 28ZM22 27.25H31.1125L26.9275 23.0447L28 22L34 28L28 34L26.9275 32.9298L31.1125 28.75H22V27.25Z"
                  fill="#605CFF"
                  :class="{ dark: THEME === 'dark', light: THEME === 'light' }"
                />
                <rect
                  x="1"
                  y="1"
                  width="54"
                  height="54"
                  rx="27"
                  stroke="#605CFF"
                  stroke-width="2"
                  :class="{ dark: THEME === 'dark', light: THEME === 'light' }"
                />
              </svg>
            </span>
          </div>
          <div
            class="navigation__footer navigation-footer"
            :class="{ opened: IS_SIDEBAR_OPENED }"
          >
            <div class="navigation-footer__customer">
              <div
                class="navigation-footer__photo"
                :class="{ dark: THEME === 'dark', light: THEME === 'light' }"
              >
                <img
                  :src="`data:image/png;base64, ${USER.photo}`"
                  alt="Red dot"
                />
              </div>

              <div class="navigation-footer__about">
                <div class="navigation-footer__name">{{ USER.nickname }}</div>
                <div class="navigation-footer__role">{{ USER.type }} ({{ USER.id_nn }})</div>
              </div>
            </div>
            <div class="navigation-footer__logout">
              <button
                class="navigation-footer__button"
                :class="{ dark: THEME === 'dark', light: THEME === 'light' }"
                @click="logOut"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.24547 8.35866C6.88089 8.35866 6.59261 8.64199 6.59261 9.00033C6.59261 9.35033 6.88089 9.64199 7.24547 9.64199H12.3327V13.6253C12.3327 15.667 10.6454 17.3337 8.55967 17.3337H4.43055C2.35327 17.3337 0.666016 15.6753 0.666016 13.6337V4.37533C0.666016 2.32533 2.36175 0.666992 4.43903 0.666992H8.57663C10.6454 0.666992 12.3327 2.32533 12.3327 4.36699V8.35866H7.24547ZM15.3579 6.11716L17.7912 8.54216C17.9162 8.66716 17.9829 8.8255 17.9829 9.0005C17.9829 9.16716 17.9162 9.33383 17.7912 9.4505L15.3579 11.8755C15.2329 12.0005 15.0662 12.0672 14.9079 12.0672C14.7412 12.0672 14.5745 12.0005 14.4495 11.8755C14.1995 11.6255 14.1995 11.2172 14.4495 10.9672L15.7829 9.64216H12.3329V8.35883H15.7829L14.4495 7.03383C14.1995 6.78383 14.1995 6.3755 14.4495 6.1255C14.6995 5.86716 15.1079 5.86716 15.3579 6.11716Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="navigation__burger burger"
      :class="{ active: isMenuActive }"
      @click="toggleMenu"
    >
      <div
        class="burger__wrapper"
        :class="{ dark: THEME === 'dark', light: THEME === 'light' }"
      >
        <span></span>
      </div>
    </div>
  </section>
</template>

<script>
import BaseButton from "@/components/UI/BaseButton.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "BaseHeader",
  components: { BaseButton },
  props: {},
  data() {
    return {
      isMenuActive: false,
    };
  },
  computed: {
    ...mapGetters([
      "USER",
      "THEME",
      "IS_SIDEBAR_OPENED",
      "TOKEN",
      // NAME
    ]),
  },
  methods: {
    ...mapActions([
      "POST_API",
      "LOGOUT",
      "SHOW_LOADER",
      "HIDE_LOADER",
      // NAME
    ]),
    changeState() {
      this.$store.state.is_sidebar_opened = !this.$store.state.is_sidebar_opened;
    },
    toggleLoader() {
      this.SHOW_LOADER();
      setTimeout(() => {
        this.HIDE_LOADER();
      }, 1000);
    },
    logOut() {
      this.POST_API({
        src: "/logout",
        data: { token: this.TOKEN },
      }).then(() => {
        this.LOGOUT();
      });
    },
    toggleMenu() {
      this.isMenuActive === true ? (this.isMenuActive = false) : (this.isMenuActive = true);
    },
    hideMenu(e) {
      if (e.target === this.$refs.menu) {
        this.isMenuActive = false;
      }
    },
    setNavigationStyles() {
      if (window.innerWidth < 990) {
        this.$refs.menu.style.top = this.$refs.navigation.scrollHeight + "px";
        this.$refs.menu.style.height = window.innerHeight - this.$refs.navigation.scrollHeight + "px";
      } else {
        this.$refs.menu.style.top = 0;
        this.isMenuActive = false;
        this.$refs.menu.style.height = "auto";
      }
    },
  },
  watch: {
    IS_SIDEBAR_OPENED() {
      setTimeout(() => {
        document.body.style.paddingLeft = `${this.$refs.navigation.getBoundingClientRect().width}px`;
      }, 1);
    },
    $route() {
      this.isMenuActive = false;
      document.body.classList.remove("lock");
      setTimeout(() => {
        document.body.style.paddingLeft = `${this.$refs.navigation.getBoundingClientRect().width}px`;
      }, 1);
    },
    isMenuActive() {
      if (this.isMenuActive) {
        document.body.classList.add("lock");
      } else {
        document.body.classList.remove("lock");
      }
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    document.body.addEventListener("click", this.hideMenu);
    window.addEventListener("resize", this.setNavigationStyles);
    this.setNavigationStyles();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setNavigationStyles);
    document.body.removeEventListener("click", this.hideMenu);
    // document.getElementById("app").style.paddingTop = 0;
  },
  unmounted() {},
  beforeUpdate() {},
  updated() {},
};
</script>

<style lang="scss">
.navigation {
  @media (min-width: 990px) {
    max-width: 80px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    height: 100vh;
    width: auto;
    display: grid;
    // justify-items: center;
    grid-template-rows: auto 1fr;
    row-gap: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    .navigation-menu__link-name,
    .navigation-footer__customer {
      display: none;
    }
    .navigation-header {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media (max-width: 989px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    padding: 15px;
    position: relative;
    z-index: 10;

    &.active {
      .navigation__content {
        user-select: initial;
        pointer-events: initial;
        backdrop-filter: blur(1.5px);
        &-container {
          transform: translateX(0);
        }
      }
    }
  }
  &.dark {
    background-color: $backgroundgDV;
  }
  &.light {
    background-color: $backgroundgLV;
  }
  &.opened {
    @media (min-width: 990px) {
      max-width: 220px;
      .navigation-menu__icon-button {
        svg {
          transform: rotate(180deg);
        }
      }
      .navigation-menu__link-name,
      .navigation-footer__customer {
        display: flex;
      }
      .navigation-header {
        padding-left: 20px;
        padding-right: 20px;
      }
      .navigation-header__logo {
        max-width: 160px;
        img {
          &.dark {
            filter: drop-shadow(0px 0.3vw $blueDV);
          }
          &.light {
            filter: drop-shadow(0 0.3vw $blueLV);
          }
        }
      }
    }
  }

  // .navigation__content

  &__content {
    @media (max-width: 989px) {
      position: fixed;
      z-index: 11;
      top: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
      user-select: none;
    }
  }

  // .navigation__content-container

  &__content-container {
    @media (max-width: 989px) {
      overflow: auto;
      max-height: 80vh;
      &.dark {
        background-color: $backgroundgDV;
      }
      &.light {
        background-color: $backgroundgLV;
      }
      // padding-left: 15px;
      // padding-right: 15px;
      max-width: max-content;
      // background-color: red;
      border-radius: 0 0 10px 0;
      transform: translateX(-100%);
      transition: transform 0.8s ease-in-out;
      // padding-top: 20px;
      padding-bottom: 20px;
      pointer-events: all;
      user-select: initial;
      display: flex;
      flex-direction: column;
      row-gap: 25px;
    }
    @media (min-width: 990px) {
      // max-height: 60vh;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  // .navigation__menu

  &__menu {
  }

  // .navigation__footer

  &__footer {
  }

  // .navigation__burger

  &__burger {
  }
}
.navigation-header {
  // .navigation-header__logo

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media (max-width: 989px) {
      max-width: 90px;
    }

    img {
      width: 100%;
      &.dark {
        @media (min-width: 990px) {
          filter: drop-shadow(0px 0.15vw $blueDV);
        }
        @media (max-width: 989px) {
          filter: drop-shadow(0px 0.25vmax $blueDV);
        }
      }
      &.light {
        @media (min-width: 990px) {
          filter: drop-shadow(0 0.15vw $blueLV);
        }
        @media (max-width: 989px) {
          filter: drop-shadow(0 0.25vmax $blueLV);
        }
      }
    }
  }
}
.navigation-menu {
  @media (max-width: 989px) {
    overflow: auto;
    height: 100%;
    &__icon-button {
      display: none;
    }
  }
  // @media (min-width: 990px) {
  @media (min-width: 990px) {
    // max-height: calc(30px * 10 + 20px * 9);
    max-height: 60vh;
  }
  @media (max-width: 989px) {
    max-height: calc(30px * 8 + 15px * 7);
  }
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  &.dark {
    ::-webkit-scrollbar {
      background-color: $borderDV;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $blueDV;
    }
  }
  &.light {
    ::-webkit-scrollbar {
      background-color: $borderLV;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $blueLV;
    }
  }

  .navigation-menu__list {
    // padding-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  // }
  @media (min-width: 990px) {
    .navigation-menu__icon {
      margin-left: -15px;
      padding-left: 20px;
      width: 40px;
    }
    &.opened {
      .navigation-menu__icon {
        margin-left: 0;
        padding-left: 20px;
        padding-right: 0;
        width: 40px;
      }
    }
  }
  @media (max-width: 989px) {
    .navigation-menu__icon {
      padding-left: 15px;
      // margin-left: -45px;
      // padding-left: 50px;
      // width: 70px;
    }
  }
  &__actions {
    // position: relative;
  }
  &__icon-button {
    position: absolute;
    bottom: 10vh;
    right: 0;

    // position: relative;
    width: 100%;
    span {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -100%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      backdrop-filter: blur(10px);
    }
    svg {
      width: 100%;
      height: auto;
    }
    path {
      &.dark {
        fill: $textLightDV;
      }
      &.light {
        fill: $textLightLV;
      }
    }
    rect {
      &.dark {
        stroke: $textLightDV;
      }
      &.light {
        stroke: $textLightLV;
      }
    }
  }
  // .navigation-menu__list

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    a.router-link-exact-active {
      .navigation-menu {
        &__list-item {
          opacity: 1;
          .navigation-menu__icon:after {
            opacity: 1;
          }
          &.dark {
            path {
              fill: $blueDV;
            }
            .navigation-menu__link-name {
              color: $blueDV;
            }
          }
          &.light {
            path {
              fill: $blueLV;
            }
            .navigation-menu__link-name {
              color: $blueLV;
            }
          }
        }
      }
    }
  }

  // .navigation-menu__list-item

  &__list-item {
    &.small {
      @media (min-width: 990px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.dark {
      &:hover {
        path {
          fill: $blueDV;
        }
        .navigation-menu__link-name {
          color: $blueDV;
        }
        .navigation-menu__icon:after {
          opacity: 0.75;
        }
      }
    }
    &.light {
      &:hover {
        path {
          fill: $blueLV;
        }
        .navigation-menu__link-name {
          color: $blueLV;
        }
        .navigation-menu__icon:after {
          opacity: 0.25;
        }
      }
    }
    display: flex;
    align-items: center;
    column-gap: 15px;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  // .navigation-menu__icon
  &__icon {
    &.dark {
      path {
        fill: $backgroundgLV;
      }
      &::after {
        background-color: $neutral;
      }
    }
    &.light {
      path {
        fill: $textLightLV;
      }
      &::after {
        background-color: $textLightLV;
      }
    }
    @media (max-width: 989px) {
      width: 35px;
    }
    @media (min-width: 990px) {
      width: 20px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;
      path {
        // transition: all 0.2s ease;
      }
    }
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: -50px;
      right: -5px;
      opacity: 0.2;
      border-radius: 0 5px 5px 0;
      opacity: 0;
      // transition: all 0.2s ease;
    }
  }
  // .navigation-menu__link-name
  &__link-name {
    // transition: all 0.2s ease;
    @media (max-width: 989px) {
      padding-right: 15px;
    }
    @media (min-width: 990px) {
      padding-right: 25px;
    }
  }
}
.navigation-footer {
  display: flex;
  align-items: center;
  @media (min-width: 990px) {
    justify-content: center;
  }
  @media (max-width: 989px) {
    justify-content: space-between;
  }
  &.opened {
    justify-content: space-between;
  }
  column-gap: 15px;
  @extend %bodyS;
  @media (max-width: 989px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (min-width: 990px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  // .navigation-footer__customer

  &__customer {
    // display: flex;
    display: grid;
    // align-items: center;
    column-gap: 8px;
  }

  &__photo {
    width: 35px;
    height: 35px;
    position: relative;
    border-radius: 50%;
    &.dark {
      background-color: $backgroundgLV;
      background-color: $mainBackgroundDV;
      background-color: $mainBackgroundLV;
    }
    &.light {
      background-color: $backgroundgDV;
      background-color: $mainBackgroundLV;
    }
    img {
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__about {
    // display: grid;
    // row-gap: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  // .navigation-footer__name

  &__name {
  }

  // .navigation-footer__role

  &__role {
  }

  // .navigation-footer__logout

  &__logout {
  }

  // .navigation-footer__button

  &__button {
    &.dark {
      path {
        fill: $backgroundgLV;
      }
    }
    &.light {
      path {
        fill: $backgroundgDV;
      }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 20px;
    svg {
      width: 100%;
      height: auto;
    }
    @media (min-width: 990px) {
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
.burger {
  @media (min-width: 990px) {
    display: none;
  }
  @media (max-width: 989px) {
    position: relative;
    z-index: 2;
    min-height: 40px; // Высота блока для удобства нажатия на иконку
    width: 40px; // Ширина блока для удобства нажатия на иконку
    // ? Двигаем на половину от разницы между width для .burger и width для .burger__wrapper что бы .burger прилип к контейнеру
    margin-right: -5px;
    // ? Двигаем на половину от разницы между width для .burger и width для .burger__wrapper что бы .burger прилип к контейнеру
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    cursor: pointer;
    &__wrapper {
      &.dark {
        span,
        &:before,
        &:after {
          background-color: $backgroundgLV;
        }
      }
      &.light {
        span,
        &:before,
        &:after {
          background-color: $backgroundgDV;
        }
      }
      width: 30px; // todo Макетная ширина бургера
      height: 20px; // todo Макетная высота бургера
      position: relative;
      display: flex;
      flex-direction: column;
      span,
      &:before,
      &:after {
        width: 100%;
        height: 2px;
        background-color: #000;
        transition: all 0.3s ease;
        transform: translateY(0);
        position: relative;
        left: 0;
      }
      &:before {
        content: "";
        animation: burgerTopDisabled 0.7s ease 0s 1;
        animation-fill-mode: forwards;
      }
      &:after {
        content: "";
        animation: burgerBottomDisabled 0.7s ease 0s 1;
        animation-fill-mode: forwards;
      }
      span {
        top: 50%;
        transform: translateY(-150%);
        // transition: background-color 0.3s ease, transform 0.3s ease 0.35s; //! Interesting effect (on desktop)
        transition: background-color 0.3s ease, transform 0.3s ease 0s;
      }

      @keyframes burgerTopDisabled {
        // Closing burger animation
        0% {
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }
        50% {
          top: 50%;
          transform: translateY(-50%);
        }
        100% {
          top: 0;
          transform: translateY(0);
        }
      }
      @keyframes burgerBottomDisabled {
        // Closing burger animation
        0% {
          top: 50%;
          transform: translateY(-250%) rotate(45deg);
        }
        50% {
          top: 50%;
          transform: translateY(-250%);
        }
        100% {
          top: 100%;
          transform: translateY(-300%);
        }
      }
    }
    &.active {
      // Opening burger animation
      .burger__wrapper {
        &:before {
          animation: burgerTopActive 0.7s ease 0s 1;
          animation-fill-mode: forwards;
        }
        span {
          transform: translateY(-150%) scale(0);
          // transition: background-color 0.3s ease, transform 0.3s ease 0s; //! Interesting effect (on desktop)
          transition: background-color 0.3s ease, transform 0.3s ease 0.3s;
        }
        &:after {
          animation: burgerBottomActive 0.7s ease 0s 1;
          animation-fill-mode: forwards;
        }
        @keyframes burgerTopActive {
          0% {
            top: 0;
            transform: translateY(0);
          }
          50% {
            top: 50%;
            transform: translateY(-50%);
          }
          100% {
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
          }
        }
        @keyframes burgerBottomActive {
          0% {
            top: 100%;
            transform: translateY(-300%);
          }
          50% {
            top: 50%;
            transform: translateY(-250%);
          }
          100% {
            top: 50%;
            transform: translateY(-250%) rotate(45deg);
          }
        }
      }
    }
  }
}
</style>
