<template>
   <button class="button">
      <span><slot name="first"></slot></span>
      <span><slot name="second"></slot></span>
   </button>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
   name: "BaseButton",
   components: {},
   props: {},
   data() {
      return {};
   },
   computed: {
      ...mapGetters([
         // NAME
      ]),
   },
   methods: {
      ...mapActions([
         // NAME
      ]),
   },
   watch: {},
   created() {},
   beforeMount() {},
   mounted() {},
   beforeUnmount() {},
   unmounted() {},
   beforeUpdate() {},
   updated() {},
};
</script>

<style lang="scss">
.button {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   min-width: 120px;
   min-height: 40px;
   padding: 10px 20px;
   border-radius: 10px;
   font-weight: 700;
   color: $dark;
   background-color: $light;
   transition: all 0.3s ease;

   &:hover {
      transform: scale(1.05);
   }
   &:focus {
      transform: scale(0.98);
   }
   &:active {
      transform: scale(0.95);
      opacity: 0.8;
   }
   display: flex;
   align-items: center;
   column-gap: 4px;
   span {
      display: flex;
      justify-content: center;
      align-items: center;
   }
   img {
      max-width: 20px;
      width: 100%;
   }
}
</style>
