import store from "@/store/store";
export const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    beforeEnter: () => {
      if (store.state.isAuthenticated) {
        return { name: "dashboard" };
      }
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/DashboardView.vue"),
    meta: { isNeedAuth: true },
  },
  {
    path: "/:url",
    name: "template-page-list",
    component: () => import("@/views/TemplatePageList.vue"),
    // props: (route) => ({ data: { ...route.props } }),
    props: (route) => ({
      data: {
        ...route.query,
        // array_name: route.query.array_name,
      },
    }),
  },
  // {
  //    path: "/blacks-list",
  //    name: "blacks-list",
  //    component: () => import("@/views/BlacksListView.vue"),
  //    meta: { isNeedAuth: true },
  // },
  // {
  //    path: "/whites-list",
  //    name: "whites-list",
  //    component: () => import("@/views/WhitesListView.vue"),
  //    meta: { isNeedAuth: true },
  // },
  // {
  //    path: "/free-whites-list",
  //    name: "free-whites-list",
  //    component: () => import("@/views/FreeWhitesListView.vue"),
  //    meta: { isNeedAuth: true },
  // },
  // {
  //    path: "/thanks-list",
  //    name: "thanks-list",
  //    component: () => import("@/views/ThanksListView.vue"),
  //    meta: { isNeedAuth: true },
  // },
  {
    path: "/blacks-list/:filename",
    name: "template-page-view-blacks",
    component: () => import("@/views/TemplatePageView.vue"),
    meta: { isNeedAuth: true },
    props: (route) => ({
      parentProps: {
        ...route.query,
      },
    }),
  },
  {
    path: "/whites-list/:filename",
    name: "template-page-view-whites",
    component: () => import("@/views/TemplatePageView.vue"),
    meta: { isNeedAuth: true },
    props: (route) => ({
      parentProps: {
        ...route.query,
      },
    }),
  },
  {
    path: "/free-whites-list/:filename",
    name: "template-page-view-free_whites",
    component: () => import("@/views/TemplatePageView.vue"),
    meta: { isNeedAuth: true },
    props: (route) => ({
      parentProps: {
        ...route.query,
      },
    }),
  },
  {
    path: "/thanks-list/:filename",
    name: "template-page-view-thanks",
    component: () => import("@/views/TemplatePageView.vue"),
    meta: { isNeedAuth: true },
    props: (route) => ({
      parentProps: {
        ...route.query,
      },
    }),
  },
  {
    path: "/earnings",
    name: "earnings",
    component: () => import("@/views/Earnings.vue"),
    meta: { isNeedAuth: true },
    props: (route) => ({ data: { ...route.query } }),
  },
  {
    path: "/blacks",
    name: "blacks",
    component: () => import("@/views/BlacksView.vue"),
    meta: { isNeedAuth: true },
  },
  {
    path: "/whites",
    name: "whites",
    component: () => import("@/views/WhitesView.vue"),
    meta: { isNeedAuth: true },
  },
  {
    path: "/domens",
    name: "domens",
    component: () => import("@/views/DomensView.vue"),
    meta: { isNeedAuth: true },
  },
  {
    path: "/statistics",
    name: "statistics",
    component: () => import("@/views/StatisticsView.vue"),
    meta: { isNeedAuth: true },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/UsersView.vue"),
    meta: { isNeedAuth: true },
    props: (route) => ({
      data: {
        ...route.query,
        // array_name: route.query.array_name,
      },
    }),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/ProfileView.vue"),
    meta: { isNeedAuth: true },
  },
  {
    path: "/404",
    name: "404",
    component: import("@/views/RedirectView"),
  },
  { path: "/:catchAll(.*)", redirect: "/404" },
];
